/** @jsx jsx */

import {
  Box,
  Flex,
  Grid,
  Heading,
  Text,
  Container,
  jsx,
  useColorMode,
  useThemeUI
} from 'theme-ui'
import useMatchMedia from '~/hooks/utils/use-match-media'
import ContentHero from '../Content/Hero'
import { GenericSectionDivider } from '../Generic/SectionDivider'
import Spacer from '../Generic/Spacer'
import { WigglySectionDivider } from '../Generic/SectionDivider/WigglySectionDivider'
import TextSectionWithVideo from '../Product/Page/TextSectionWithVideo'
import GenericBlock from './Block'
import MacaroniGraph from './Graph'
import { useEffect, useState } from 'react'

const UsVsThem = ({ page }) => {
  const { title, description, media, maskedVideo } = page.sections[0]

  const { theme } = useThemeUI()
  const [, setColorMode] = useColorMode()
  //run on mount
  useEffect(() => {
    setColorMode('Us-Vs-Them')
  }, [])

  const mobile = useMatchMedia('(max-width: 767px)')

  const [small, setSmall] = useState(false)

  useEffect(() => {
    setSmall(mobile)
  }, [mobile])

  const localButtonStyle = {
    color: '#FFDD00',
    backgroundColor: '#2B38BD'
  }
  let userAgent =
    typeof window !== 'undefined' ? window.navigator.userAgent : null
  return (
    <Container
      variant="fullWidth"
      sx={{
        flexDirection: 'column',
        backgroundColor: 'frenchPass',
        overflowX: 'hidden'
      }}
    >
      <Box
        sx={{
          marginBottom: ['-53px', 5],
          color: 'frenchPass',
          position: 'relative',
          zIndex: 0
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            pt: '100px',
            pb: '80px',
            px: '30px',
            backgroundColor: 'grape'
          }}
        >
          <Heading
            as="h1"
            variant="text.h1"
            sx={{
              fontSize: ['32px', '60px'],
              width: ['85%', '100%'],
              textAlign: 'center',
              zIndex: 1,
              color: 'inherit'
            }}
          >
            {title}
          </Heading>
          {description && (
            <Text
              sx={{
                variant: 'text.body',
                textAlign: 'center',
                fontSize: ['18px', 3],
                maxWidth: '640px',
                m: '0 auto',
                mt: 4,
                p: {
                  m: 0
                },
                zIndex: 1
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: description.childMarkdownRemark.html
                }}
              />
            </Text>
          )}
        </Flex>
        <Grid sx={{ transform: ['none'], mb: ['118px', 0] }}>
          <WigglySectionDivider
            small={small}
            topColor={theme.colors.grape}
            bottomColor={theme.colors.frenchPass}
          />
        </Grid>
      </Box>
      <Flex sx={{ flexDirection: 'column' }}>
        <Heading
          as="h2"
          variant="text.h1"
          sx={{ color: 'governorBay', textAlign: 'center' }}
        >
          HOW WE STACK UP
        </Heading>
        <Spacer amount="77px" />
        <MacaroniGraph
          heading="NUTRIENTS<br>FROM PLANTS*"
          subcopy="*Number of Nutrients with 10% or more of the Daily Value in one prepared serving (about 1 cup), as communicated on the box. Pulled from each brand’s website on 10/1/21"
          yAxis={['0', '5', '10', '15', '20']}
          xAxis={[
            {
              company: 'Kraft Mac & Cheese ®',
              product: '(Blue Box)',
              yValue: '0+6'
            },
            {
              company: 'Goodles ®',
              product: 'Cheddy Mac',
              yValue: '20',
              featured: true
            },
            {
              company: "Annie's Shells with White Cheddar ®",
              product: '(Purple Box)',
              yValue: '0+10'
            }
          ]}
        />
        <Spacer amount={['-70px', '100px']} />

        <GenericSectionDivider
          fill="#2B38BD"
          sx={{
            transform: [
              'rotate(180deg) scale(0.3) translate(130%, 100%)',
              'rotate(180deg) scale(1.1) translate(4%, 99%)'
            ],
            transformOrigin: 'bottom',

            width: ['400%', '100%'],
            mb: '-2px'
          }}
        />
        <GenericBlock
          {...media[0]}
          mediaWidth={['152px', '251px']}
          sx={{ backgroundColor: 'governorBay', color: 'frenchPass' }}
        />
        <GenericSectionDivider
          fill="#2B38BD"
          sx={{
            transform: [
              'scale(0.3) translateX(-120%)',
              'scaleX(1.1) translateX(4%)'
            ],
            transformOrigin: 'top',
            width: ['400%', '100%'],
            mt: '-2px'
          }}
        />
        <Spacer amount={['-30px', '90px']} />

        <MacaroniGraph
          heading="PROTEIN<br>PER SERVING*"
          subcopy="*Per Serving as prepared (about 1 cup), as communicated on the box. Pulled from each brand’s website on 10/1/21"
          yAxis={['0g', '5g', '10g', '15g']}
          xAxis={[
            {
              company: 'Kraft Mac & Cheese ®',
              product: '(Blue Box)',
              yValue: '10g'
            },
            {
              company: 'Goodles ®',
              product: 'Cheddy Mac',
              yValue: '15g',
              featured: true
            },
            {
              company: "Annie's Shells with White Cheddar ®",
              product: '(Purple Box)',
              yValue: '10g+9'
            }
          ]}
        />
        <Spacer amount={['60px', '130px']} />

        <MacaroniGraph
          heading="FIBER<br>PER SERVING*"
          subcopy="*Per Serving as prepared (about 1 cup), as communicated on the box. Pulled from each brand’s website on 10/1/21"
          yAxis={['0g', '2g', '4g', '6g']}
          xAxis={[
            {
              company: 'Kraft Mac & Cheese ®',
              product: '(Blue Box)',
              yValue: '2g'
            },
            {
              company: 'Goodles ®',
              product: 'Cheddy Mac',
              yValue: '6g',
              featured: true
            },
            {
              company: "Annie's Shells with White Cheddar ®",
              product: '(Purple Box)',
              yValue: '2g'
            }
          ]}
        />
        <Spacer amount={['40px', '114px']} />
        <ContentHero
          hero={media[1]}
          sx={{
            maskImage: 'url(/images/wiggle-mask.svg)',
            maskSize: [
              userAgent && userAgent.match(/iPhone/i) ? 'cover' : '100% 100%',
              'contain'
            ],
            '.gatsby-image-wrapper': {
              '& > div': {
                paddingBottom: ['114% !important', '61.111% !important']
              }
            }
          }}
        />
      </Flex>
      <TextSectionWithVideo
        buttonUrl="/shop"
        buttonStyle={localButtonStyle}
        url={maskedVideo?.file?.url}
        containerStyle={{
          color: '#AEE0FD',
          backgroundColor: '#6E5BE8',
          mt: '-65px',
          pt: '133px'
        }}
      />
    </Container>
  )
}
export default UsVsThem
