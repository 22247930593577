/**@jsx jsx */
import { Flex, Box, Heading, jsx } from 'theme-ui'
import Img from 'gatsby'
const GenericBlock = ({
  title,
  description,
  media,
  mediaWidth = 'auto',
  ...props
}) => {
  return (
    <Flex
      sx={{
        gap: ['30px', '70px', '150px'],
        padding: ['35px 20px', '85px 20px'],
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: ['center', 'left'],
        flexDirection: ['column', 'row']
      }}
      {...props}
    >
      <Box sx={{ width: mediaWidth }}>
        {media && media.fluid && <Img src={media.fluid} />}
        {media && media.svg && (
          <div
            sx={{ svg: { height: 'auto' } }}
            dangerouslySetInnerHTML={{ __html: media.svg.content }}
          />
        )}
      </Box>
      <Flex
        sx={{
          flexDirection: 'column',
          maxWidth: ['315px', '420px'],
          justifyContent: 'center'
        }}
      >
        {title && (
          <Heading as="h2" sx={{ fontSize: ['32px', 6] }}>
            {title}
          </Heading>
        )}
        {description && (
          <Box
            sx={{
              fontSize: ['18px', 3],
              fontWeight: 'body',
              p: { mt: '30px', mb: 0 }
            }}
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html
            }}
          />
        )}
      </Flex>
    </Flex>
  )
}

export default GenericBlock
