/**jsx @jsx */
import React, { useEffect, useState } from 'react'
import { Flex, Heading, Text } from 'theme-ui'
import useMatchMedia from '~/hooks/utils/use-match-media'
import GraphBar from './Bar'
import GraphLine from './Line'
const MacaroniGraph = ({ heading, subcopy, yAxis, xAxis }) => {
  const [gridGap, setGridGap] = useState(80)

  const getBarHeight = yValue => {
    const splitYValue = yValue.split('+')
    if (!yAxis.includes(splitYValue[0])) return '0%'
    const totalPointsY = yAxis.length - 1
    const index = yAxis.indexOf(splitYValue[0])
    let height = (index / totalPointsY) * 100
    if (splitYValue.length > 1) {
      height += parseInt(splitYValue[1])
    }
    return height + '%'
  }

  const isMobileViewport = useMatchMedia('(max-width: 767px)')
  const isSmallDesktopViewport = useMatchMedia('(max-width: 1365px)')

  useEffect(() => {
    if (isMobileViewport) {
      setGridGap(40)
    } else {
      setGridGap(120)
    }
  }, [isMobileViewport])

  return (
    <Flex
      sx={{
        padding: ['0 20px 0 40px', '0 71px'],
        justifyContent: 'space-between',
        flexDirection: ['column', isSmallDesktopViewport ? 'column' : 'row'],
        maxWidth: ['375px', '1440px'],
        width: ['100%', null, isSmallDesktopViewport ? 'auto' : '100%'],
        margin: '0 auto',
        gap: [0, '55px'],
        flexWrap: 'wrap',
        paddingBottom: ['55px', '110px']
      }}
    >
      <Flex sx={{ flexDirection: 'column' }}>
        <Heading
          sx={{
            fontSize: ['22px', '60px'],
            color: 'grape',
            lineHeight: '100%',
            ml: ['-16px', '-45px', 0]
          }}
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <Text
          sx={{
            maxWidth: '433px',
            mt: '15px',
            ml: [null, '-45px', 0],
            color: 'grape',
            fontSize: 2,
            lineHeight: '129%',

            variant: 'responsive.tabletDesktop'
          }}
        >
          {subcopy}
        </Text>
      </Flex>
      <Flex
        sx={{
          flexDirection: 'column',
          width: [null, '100%', '776px'],
          position: 'relative',
          mt: ['78px', '109px']
        }}
      >
        {yAxis
          .slice()
          .reverse()
          .map((point, i) => (
            <GraphLine
              point={point}
              forwardSx={{
                mb: i !== yAxis.slice().length - 1 && gridGap + 'px'
              }}
            />
          ))}
        <Flex
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            bottom: 0,
            left: 0,
            justifyContent: 'center',
            alignItems: 'flex-end',
            '& > div:not(:last-child)': {
              mr: ['61px', '145px']
            }
          }}
        >
          {xAxis.map((bar, i) => (
            <GraphBar
              yAxis={yAxis}
              key={`${bar.company}-bar-${i}`}
              bar={bar}
              height={getBarHeight(bar.yValue)}
              gridGap={gridGap}
              isMobileViewport={isMobileViewport}
            />
          ))}
        </Flex>
      </Flex>
      <Text
        sx={{
          maxWidth: '315px',
          mt: '74px',
          color: 'grape',
          fontSize: 1,
          lineHeight: '129%',
          variant: 'responsive.mobile'
        }}
      >
        {subcopy}
      </Text>
    </Flex>
  )
}

export default MacaroniGraph
